import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "calendar_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "p-d-flex p-jc-between p-px-3" }
const _hoisted_7 = { class: "p-d-flex w100" }
const _hoisted_8 = { class: "calendar" }
const _hoisted_9 = {
  key: 0,
  class: "p-text-center"
}
const _hoisted_10 = { class: "vuecal__cell-date" }
const _hoisted_11 = { class: "events" }
const _hoisted_12 = { class: "vuecal__event-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_vue_cal = _resolveComponent("vue-cal")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t("calendar.month_view.title")), 1),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("calendar.month_view.subtitle")), 1)
      ])
    ]),
    _createVNode("section", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.serviceId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.serviceId = $event)),
              options: _ctx.services,
              optionLabel: "name",
              optionValue: "id",
              placeholder: "Servizi",
              loading: _ctx.servicesIsLoading,
              class: "w100 p-mr-2",
              showClear: "",
              onChange: _ctx.onServiceChange
            }, null, 8, ["modelValue", "options", "loading", "onChange"]),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.technicianId,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.technicianId = $event)),
              options: _ctx.technicians,
              optionLabel: (t) => `${t.name} ${t.surname}`,
              optionValue: "id",
              placeholder: "Tecnici",
              loading: _ctx.techniciansIsLoading,
              class: "w100 p-mr-2",
              showClear: "",
              onChange: _ctx.onTechnicianChange
            }, null, 8, ["modelValue", "options", "optionLabel", "loading", "onChange"])
          ]),
          _createVNode(_component_Button, {
            label: _ctx.$t('generic.btn_confirm_filters_label'),
            class: "p-button-secondary",
            icon: "pi pi-search",
            iconPos: "right",
            onClick: _ctx.applyFilters
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _createVNode("div", _hoisted_8, [
        (_ctx.requestPending)
          ? (_openBlock(), _createBlock("p", _hoisted_9, _toDisplayString(_ctx.$t("loading_label")), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_OverlayPanel, {
          ref: "evDetails",
          appendTo: "body",
          showCloseIcon: true,
          id: "overlay_panel",
          style: {"width":"450px"},
          breakpoints: { '960px': '75vw' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DataTable, {
              value: _ctx.overlayData,
              paginator: false,
              rows: 5,
              class: 'overlay_table_panel'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "service_name",
                  header: "Servizio"
                }),
                _createVNode(_component_Column, {
                  field: "status",
                  header: "Stato"
                }),
                _createVNode(_component_Column, {
                  field: "technician",
                  header: "Tecnico"
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 512),
        _createVNode(_component_vue_cal, {
          "disable-views": ['years', 'year', 'week', 'day'],
          events: _ctx.events,
          selectedDate: _ctx.startDate,
          time: false,
          "active-view": "month",
          "hide-view-selector": "",
          "events-on-month-view": "short",
          locale: "it",
          onViewChange: _ctx.onViewChange
        }, {
          "cell-content": _withCtx(({ cell, events }) => [
            _createVNode("div", {
              class: ["custom-container", _ctx.getClassCell(cell, events)],
              onClick: ($event: any) => (_ctx.onCellClick(cell.startDate, events))
            }, [
              _createVNode("div", _hoisted_10, _toDisplayString(cell.content), 1),
              _createVNode("div", _hoisted_11, [
                (events.length)
                  ? (_openBlock(), _createBlock("div", {
                      key: 0,
                      class: "msg-title",
                      onMouseover: ($event: any) => (_ctx.onMouseOver($event, cell, events))
                    }, _toDisplayString(_ctx.$t(
                        "calendar.month_view.event.title"
                      )), 41, ["onMouseover"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(events, (event, idx) => {
                  return (_openBlock(), _createBlock("div", {
                    class: "vuecal__event",
                    key: `event-${idx}`
                  }, [
                    _createVNode("div", _hoisted_12, _toDisplayString(event.title), 1)
                  ]))
                }), 128))
              ])
            ], 10, ["onClick"])
          ]),
          _: 1
        }, 8, ["events", "selectedDate", "onViewChange"])
      ])
    ])
  ]))
}