import { ProviderTechnician } from "@/model/api/Provider";
import { Service } from "@/model/api/Service";
import { authStore } from "@/modules/auth/store";
import { providerCalendarService } from "@services/providerCalendar.service";
import moment from "moment";
import OverlayPanel from "primevue/overlaypanel";
import { Options, Vue } from "vue-class-component";
import { CalendarRoutesEnum } from "../../router";
import { calendarStore } from "@/modules/calendar/store";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  name: "CalendarPage",
})
export default class CalendarPage extends Vue {
  /**
   * Resulst of fetching events
   */
  get events() {
    return calendarStore.getters.vueCalMonthEvents || [];
  }

  startDate   = new Date();
  currentDate = new Date();

  
  /**
   * Selected service from dropdown
   */
  serviceId?: number = null; 
  /**
   * Available service options for the dropdown
   */
  services: Service[] = null;
  

  /**
   * Selected technician from dropdown
   */
  technicianId?: number = null; 
  /**
   * Available technician options for the dropdown
   */
  technicians: ProviderTechnician[] = null;


  /**
   * A date is valid only if is in the future
   * @param date 
   * @returns 
   */
  isValidDate(date: any) {
    if (moment(date).isBefore(moment(), 'day')) {
      return false;
    }
    return true;
  }

  getClassCell(cell: any, events: any[]) {
    const result = []; 

    if (!events?.length && !this.isValidDate(cell.formattedDate)) {
      result.push('blocked') ;
    }

    if (events.length) {
      result.push('has-events');

      const { count, total } = events[0].data;

      if (count === 0) {
        result.push('no-sale')
      } 
      else if (count === total) {
        result.push('sold-out');
      }

      return result.join(' ');
    }
    
    return 'nothing';
  }

  applyFilters() {
    this.loadEvents();
  }

  onCellClick(date: Date, events: any[]) {
    if (!events?.length) {
      return;
    }

    this.$router.push({
      name: CalendarRoutesEnum.CALENDAR_DATE,
      params: {
        day: (date as any).format('YYYY-MM-DD')
      }
    })
  }

  overlayData: any[] = null;
  onMouseOver(event: MouseEvent, cell: any, events: any[]) {
    if (!this.isValidDate(cell.formattedDate) || !events?.length) {
      return;
    } 
    console.debug("events",events);
    const e = events[0];
    this.overlayData = e.data.services
      .sort((
        {service_id: aid}, 
        {service_id: bid}
      ) => aid - bid)
      .map(s => {
        return {
          service_name: s.service_name,
          technician: `${s.user_name} ${s.user_surname}`,
          status: s.order ? 'Acquistato' : 'Acquistabile'
        }
      });
    (this.$refs.evDetails as OverlayPanel).toggle(event);
  }

  onViewChange(event: any) {
    this.currentDate = new Date(event.startDate);
    this.loadEvents();
  }

  onServiceChange(){
    this.loadTechinicians();
  }
  onTechnicianChange() {
    this.loadServices();
  }

  get currentProviderId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }

  private async loadEvents() {
    return this.$waitFor( async () => {
      return await calendarStore.actions.fetchEvents({
        currentProviderId : this.currentProviderId,
        month             : (this.currentDate as any).format('YYYY-MM'),
        serviceId         : this.serviceId,
        technicianId      : this.technicianId
      });
    });
  }

  techniciansIsLoading: boolean = false;
  private async loadTechinicians() {
    try {
      this.techniciansIsLoading = true;
      this.technicians = await providerCalendarService.fetchTechnicians(
        this.currentProviderId,
        this.serviceId
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.techniciansIsLoading = false;
    }
  }

  servicesIsLoading: boolean = false;
  private async loadServices() {
    try {
      this.servicesIsLoading = true;
      this.services = await providerCalendarService.fetchServices(
        this.currentProviderId,
        this.technicianId
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.servicesIsLoading = false;
    }
  }

  private checkQueryParams() {
    const { technicianId, serviceId } = this.$route.query || {};
    
    this.technicianId = technicianId  ? +technicianId : null;
    this.serviceId    = serviceId     ? +serviceId    : null;
  }

  created() {
    this.checkQueryParams();

    this.loadTechinicians();
    this.loadServices();
    this.loadEvents();
  }

}