import { authStore } from "@/modules/auth/store";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CalendarRoutesEnum } from "../../router";
import { calendarStore } from "@/modules/calendar/store";
import { providerCalendarService } from "@services/providerCalendar.service";
@Options({})
export default class CalendarDatePage extends Vue {
  /**
   * Selected date: YYYY-MM-DD
   */
  @Prop() readonly day!: string;

  get title() {
    return moment(this.day).format('dddd DD MMMM');
  }

  get currentProviderId() {
    return authStore.getters.me?.entity_id;
  }

  get events(){
    return calendarStore.getters.getVueCalDayEvents(this.day);
  }
  
  get noSelection() {
    return !this.selectedOrderIds?.length;
  }

  /**
   * If true, display an alert dialog before delete some service slot.
   */
   showDeleteDialog: boolean = false;

   selectedOrderIds: Array<number> = [];

  minDate     = new Date();
  startDate   = new Date();
  currentDate = new Date();

  selectedEvent: any;

  previousDay() {
    this.$router.push({
      name: CalendarRoutesEnum.CALENDAR_DATE,
      params: {
        day: moment(this.day)
          .subtract(1, 'day')
          .format('yyyy-MM-DD')
      },
      force: true
    })
  }

  nextDay() {
    this.$router.push({
      name: CalendarRoutesEnum.CALENDAR_DATE,
      params: {
        day: moment(this.day)
          .add(1, 'day')
          .format('yyyy-MM-DD')
      },
      force: true
    })
  }

  onEventClick(x: any) {
    this.$router.push({
      name: CalendarRoutesEnum.CALENDAR_SERVICE,
      params: {
        service: JSON.stringify(x.data)
      }
    })
  }

  /**
   * Fetch events only if month is changed
   */
  private loadEvents(month: string) {
    
    if (!calendarStore.getters.getIsSameMonth(month)) {
      calendarStore.actions.fetchEvents({
        currentProviderId: this.currentProviderId,
        month
      });
    }
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    this.loadEvents(to.params.day.slice(0, this.day.length - 3));
    next();
  }

  created() {
    if (!this.day) {
      this.$router.replace({
        name: CalendarRoutesEnum.CALENDAR
      });

      return;
    }

    this.loadEvents(this.day.slice(0, this.day.length - 3));
  }

  cancelEvent(event) {
    this.selectedEvent = event;
    this.showDeleteDialog = true;
  }

  deleteServiceDate() {
    this.$waitFor(async () => {
      await providerCalendarService.cancelServiceDate(this.selectedEvent.data.order_id)

      this.refresh();
    }, 'Cancellazione servizio non riuscita');
    this.showDeleteDialog = false;
  }

  refresh() {
    this.loadEvents(this.day.slice(0, this.day.length - 3));
  }
}